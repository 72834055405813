:root {
  /* --typo: #293244; */

  --color-fond: #c7c7c7;
  --color-noir: #222222;

  --color-grisB: rgb(153, 167, 177);
  --color-grisBC: rgb(210, 218, 223);
  --color-grisC: #f4f3f3;
  --color-grisF: #eaeaea;
  --color-vert: #3f8a48;
  --color-vertM: #60a552;
  --color-vertF: #00ff06;
  --color-jaune: #fed833;
  --color-jauneV: #cffd6a;
  --color-rouge: #ee5b2e;
  --color-terre: #e9763e;
  --color-peau: #e6b995;
  --color-rose: #f5b7d2;
  --color-bleuF: #408ac8;
  --color-bleuC: #74c7ef;
}

select {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none; /* get rid of default appearance for IE8, 9 and 10*/
}

[hidden] {
  display: none !important;
}

@font-face {
  font-family: "wondertyperegular";
  src: url("../Fonts/wondertype-regular-webfont.woff2") format("woff2"),
    url("../Fonts/wondertype-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "fontlabregular";
  src: url("../Fonts/fontlab-webfont.eot");
  src: url("../Fonts/fontlab-webfont.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/fontlab-webfont.woff2") format("woff2"),
    url("../Fonts/fontlab-webfont.woff") format("woff"),
    url("../Fonts/fontlab-webfont.ttf") format("truetype"),
    url("../Fonts/fontlab-webfont.svg#fontlabregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "punk_novaregular";
  src: url("../Fonts/punknova-regular-webfont.woff2") format("woff2"),
    url("../Fonts/punknova-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "isobareregular";
  src: url("../Fonts/isobare-regular-webfont.woff2") format("woff2"),
    url("../Fonts/isobare-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: white;
  font-family: "wondertyperegular", Helvetica, sans-serif;
  /* text-shadow: var(--color-grey) 1px 0 10px; */
  padding: 0px;
  margin: 0px;

  /* scrollbar hidden */
  -ms-overflow-style: none;
  scrollbar-width: none;

  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body::-webkit-scrollbar {
  display: none;
}

html {
  background-color: var(--color-grisF);
}

button {
  -webkit-tap-highlight-color: none;
}

/* BLOC TITRE */

.blackout {
  z-index: 2;
  position: fixed;
  background-color: rgb(94, 31, 153);
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  mix-blend-mode: difference;
}

div.titres {
  z-index: 1;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  overflow: visible;
  height: 3em;
  width: 100%;
  background-color: var(--color-grisBC);
  border-bottom: var(--color-vertM) 1px solid;
  vertical-align: middle;

  -webkit-transition: all 0.8s ease-out;
  -moz-transition: all 0.8s ease-out;
  -o-transition: all 0.8s ease-out;
  transition: all 0.8s ease-out;
}

div.titres_bloc {
  position: relative;
  display: flex;
  border-left: solid var(--color-vertM) 1px;
  padding-left: 1em;
  padding-right: 1em;
}

.link-button {
  cursor: pointer;
  margin: auto;
  font-size: 1em;
  text-decoration: none;
  color: var(--color-vertM);
  font-family: "wondertyperegular", Helvetica, sans-serif;
}

@media (max-width: 800px) {
  .link-button {
    font-size: 0.8em;
  }
}

div.titres > a:hover {
  background-color: var(--hover);
}
div.titres > a:focus {
  background-color: var(--hover);
}

div.titres > div > button:hover {
  outline: none;
  background-color: var(--color-vert);
}
div.titres > div > button:focus {
  outline: none;
  /* background-color: rgb(46, 199, 54); */
}

/* BLOC IMAGE */

.images-infos {
  position: relative;
  padding-right: 2em;
  color: var(--color-vertM);
  left: 1em;
  margin: auto;
  margin-left: 0;
  z-index: 4;
}

.images-infos-pages {
  text-shadow: -1px 0 var(--color-vertM), 0 1px var(--color-vertM),
    1px 0 var(--color-vertM), 0 -1px var(--color-vertM);
  font-size: 1.5em;
  position: relative;
  color: var(--color-jaune);
  margin: auto;
  margin-right: 1em;
  z-index: 4;
}

@media (max-width: 800px) {
  .images-infos,
  .images-infos-pages {
    font-size: 0.6em;
  }
}

.images-flex {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 4em;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  gap: 1em;
  padding-bottom: 1rem;
  min-height: 90vh;
  -webkit-transition: all 0.8s ease-out;
  -moz-transition: all 0.8s ease-out;
  -o-transition: all 0.8s ease-out;
  transition: all 0.8s ease-out;
}

.columns {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.columns > div {
}

.images_fond_zomm {
  position: fixed;
  display: flex;
  z-index: 2;
  height: 100%;
  width: 100%;
  background-color: var(--color-grisF);
  scroll-behavior: unset;
}

.images_zoom {
  position: fixed;
  display: flex;
  justify-content: center;
  height: 80%;
  width: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.miniature_zoom {
  object-fit: contain;
  text-align: center;
  max-width: 100%;
  max-height: 100%;
}

.miniature {
  position: relative;
  width: 100%;
  max-height: 100%;
}
.contain {
  position: relative;
  width: 100%;
  object-fit: cover;
  /* transition: opacity 1s linear; */
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.tourne {
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
