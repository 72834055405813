.filters_component {
  position: fixed;
  background-color: var(--color-grisBC);
  z-index: 2;
  top: 5em;
  left: 2em;
  border-radius: 0.5em 0.5em 0.5em 0.5em;
  border: 1px var(--color-vertM) solid;
}

.blend {
  mix-blend-mode: difference;
  position: fixed;
  background-color: var(--color-vertM);
}

.filters_drag_button {
  position: relative;
  width: 100%;
  height: 2em;
  background-color: var(--color-grisB);
  border-bottom: 1px solid var(--color-grisB);
  border-radius: 0.4em 0.5em 0 0;
}

.filters_bloc {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 0 0 0.5em 0.5em;
}

.filters_title {
  cursor: pointer;
  margin: 0;
  padding: 0.4em 1em 0.4em 1em;
  font-family: Helvetica, sans-serif;
  font-size: 0.8em;
  color: var(--color-vertM);
  border-bottom: 1px var(--color-grisB) solid;

  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.filters_title:hover {
  background-color: var(--color-grisB);
  color: var(--color-grisF);
}

.filters_title:checked {
  background-color: var(--color-rouge);
  color: var(--color-jaune);
}

.filters_bloc > span:last-child {
  border-bottom: none;
}
