/* MATTER BOUTONS */

.canvas {
  position: fixed;
  z-index: 1;
  pointer-events: none;
  background-color: var(--color-fond);
}

/* INFOS */

.copyright-info {
  font-family: Helvetica, sans-serif;
}

.info-bloc {
  width: 100vw;
  height: 100vh;
}

.infos-emails-bloc {
  text-shadow: -1px 0 var(--color-noir), 0 1px var(--color-noir),
    1px 0 var(--color-noir), 0 -1px var(--color-noir);
  /* isolation: isolate;
  mix-blend-mode: saturation;
  color: rgb(255, 255, 255); */

  color: var(--color-jauneV);
  z-index: 3;
  padding: 0.25em;
  position: absolute;
  font-size: 3.4em;
  top: 0;
}

a.infos_link {
  text-shadow: -1px 0 var(--color-noir), 0 1px var(--color-noir),
    1px 0 var(--color-noir), 0 -1px var(--color-noir);
  /* isolation: isolate; */
  /* mix-blend-mode: difference; */
  color: var(--color-jauneV);
  list-style: none;
  text-decoration: none;
  -webkit-tap-highlight-color: none;
}

a.infos_link:hover {
  text-shadow: -1px 0 var(--color-noir), 0 1px var(--color-noir),
    1px 0 var(--color-noir), 0 -1px var(--color-noir);
  color: var(--color-vertF);
}

.infos-clients-bloc {
  /* mix-blend-mode: exclusion; */

  /* isolation: isolate;
  mix-blend-mode: saturation;
  color: rgb(255, 255, 255); */

  color: var(--color-bleuF);
  text-shadow: -1px 0 var(--color-noir), 0 1px var(--color-noir),
    1px 0 var(--color-noir), 0 -1px var(--color-noir);
  z-index: 3;
  padding: 0.25em;
  position: absolute;
  font-size: 3.4em;
  bottom: 0;
  width: 70%;
  /* no onClick event */
  pointer-events: none;
}

.infos-copyright-bloc {
  cursor: help;
  text-shadow: -1px 0 var(--color-noir), 0 1px var(--color-noir),
    1px 0 var(--color-noir), 0 -1px var(--color-noir);
  /* isolation: isolate;
  mix-blend-mode: saturation;
  color: rgb(255, 255, 255); */

  z-index: 3;
  color: var(--color-rose);
  padding: 0.25em;
  position: absolute;
  font-size: 3.4em;

  transform-origin: top right;
  -webkit-transform-origin: top right;
  -moz-transform-origin: top right;
  -o-transform-origin: top right;

  right: 0;
  top: 0;
  transform: translateY(100%) rotate(90deg) translateX(75%);
  -webkit-tform: rans translateY(100%) rotate(90deg) translateX(75%);
  -moz-transform: translateY(100%) rotate(90deg) translateX(75%);
  -o-transform: translateY(100%) rotate(90deg) translateX(75%);
}

.infos-home-bloc {
  text-shadow: -1px 0 var(--color-noir), 0 1px var(--color-noir),
    1px 0 var(--color-noir), 0 -1px var(--color-noir);
  /* isolation: isolate;
  mix-blend-mode: saturation;
  color: rgb(255, 255, 255); */

  z-index: 3;
  color: var(--color-jaune);
  padding: 0.25em;
  position: absolute;
  font-size: 3.4em;
  bottom: 0;
  right: 0;
}

@media (max-width: 800px) {
  .infos-clients-bloc,
  .infos-copyright-bloc,
  .infos-emails-bloc,
  .infos-home-bloc {
    font-size: 1.5em;
  }
}
