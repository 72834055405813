.infinite-scroll-component {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.infinite-scroll-component > img {
  height: 200px;
  margin-bottom: 15px;
}

div.image_full_screen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100vw;
  height: 100vh;
  background-color: rgb(71, 71, 71);
}

/* div.image_full_screen > img {
  position: absolute;
  width: auto;
  height: auto;
  margin: 100px;
} */
