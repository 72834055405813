/* CROSS */

.filters_cross {
  cursor: pointer;
  z-index: 2;
  border-radius: 0 0.4em 0 0;
  background-color: var(--color-vertM);
  height: 2em;
  width: 30%;
  color: var(--color-vertF);
  position: absolute;
  right: 0;
  top: 0;
}

.close_div {
  position: absolute;
  display: flex;
  z-index: 11;
  top: 1em;
  right: 1em;
  background-color: transparent;
  border-radius: 0.5em;
  /* border: var(--color-noir) solid 1px; */
  width: 50px;
  height: 50px;
}

.close_div:hover {
  cursor: cell;
}

.close_filters {
  position: absolute;
  top: 20%;
  left: 50%;
}

.close_zoom {
  position: relative;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close {
}
.close:before:hover {
  opacity: 1;
  color: var(--color-vertF);
}
.close:before,
.close:after {
  position: absolute;
  content: " ";
  /* Taille */
  height: 20px;
  width: 1px;
  background-color: var(--color-jauneV);
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

/* Arrow */

.arrow-right,
.arrow-left {
  position: absolute;
  z-index: 9;
  display: block;
  width: 15px;
  height: 15px;
  border-top: 1px solid var(--color-jauneV);
  border-left: 1px solid var(--color-jauneV);
  bottom: 2em;
}

.arrow-right {
  transform: rotate(135deg);
  right: 2.5em;
}

.arrow-left {
  transform: rotate(-45deg);
  left: 2.5em;
}

/* NUMEROS */

.page {
  position: absolute;
  font-family: "Courier New", Courier, monospace;
  z-index: 9;
  left: 2em;
  top: 2em;
  color: var(--color-jauneV);
  font-size: 1em;
  letter-spacing: 0.1em;
}
